.platform-experts-list {
  position: relative;
  width: 800px;

  &--loading {
    height: 100px;
    width: 400px; }

  &__list {
    display: flex;
    flex-wrap: wrap; }

  &__list-item {
    margin-right: 40px;
    flex-shrink: 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0px 10px 20px rgba(0,0,0,.1);
    background-size: 50px 50px;
    background-position: center;
    background-image: url(../../../../../assets/icons/user.svg);
    background-repeat: no-repeat;
    cursor: pointer; } }

@import "../../../../styles/colors.sass";

.form-header {
  background-image: url(../../../../assets/images/artist-crm/artist-header-bg.png), linear-gradient(to bottom, $black 0%, $black 70%,$black 70%, transparent 70%, transparent 100%);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-top: 63px;
  padding-bottom: 113px;

  @media (min-width: 1440px) {
    background-image: url(../../../../assets/images/artist-crm/artist-header-bg@2x.png), linear-gradient(to bottom, $black 0%, $black 70%,$black 70%, transparent 70%, transparent 100%); }

  &__description {
    max-width: 800px; }

  &__title {
    display: flex;
    align-items: center; }

  &__text {
    font-size: 14px;
    line-height: 22px;
    color: $white;
    width: 700px; }

  &__controls {
    display: flex;
    justify-content: flex-end;

    & > div {
      margin-left: 15px; } }

  &__info {
    margin-top: 27px;
    text-align: right;

    &--no-margin {
      margin-top: 0; } }

  &__info-title {
    font-size: 14px;
    line-height: 22px;
    color: $white;
    opacity: .5; }

  &__info-status {
    color: $white;
    letter-spacing: 2px;
    margin-top: 10px; }

  &__back {
    margin-right: 30px;
    line-height: 0;
    flex-shrink: 0;
    cursor: pointer; }

  &__info-save {
    text-align: right;
    color: $white;
    font-size: 14px;
    margin-bottom: 10px; }

  &--fixed {
    background-image: none;
    background-color: $black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 20px 0;
    animation: appear .2s 1;
    animation-fill-mode: forwards;
    transform: translateY(-100%);

    .form-header__info-save {
      margin-bottom: 0px; }

    .form-header__info {
      margin-top: 20px; } }

  &--fading {
    animation: fade .2s 1;
    animation-fill-mode: forwards; }

  &__selection-policy {
    display: flex;
    align-items: center; }

  &__selection-policy-icon {
    margin-right: 24px; }

  &__selection-policy-text {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
    text-decoration-line: underline;
    color: $white; } }

@keyframes appear {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(0); } }

@keyframes fade {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-100%); } }

@import "../../../../styles/colors";

.checkbox-component {
  display: flex;
  align-items: center;

  input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked {
      & + .checkbox-component__check {
        background-color: $purple;
        border: none;

        svg {
          display: block; } } } }

  &__check {
    width: 30px;
    height: 30px;
    border-radius: 7px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $very-light-blue;
    box-sizing: border-box;
    flex-shrink: 0;
    margin-right: 20px;

    svg {
      display: none; } }


  &__content {
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: $black;

    a {
      font-weight: 400;
      color: $purple; } } }

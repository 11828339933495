.page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__body {
    padding-bottom: 150px;
    flex-grow: 1;
    display: flex;
    flex-direction: column; } }

@import "../../../../styles/colors";

.expert-profile-page {
  &__body {
    padding-top: 50px; }

  &__platforms {
    width: 800px; }

  &__form {
    width: 800px; }

  &__platform-link {
    text-decoration: none;
    color: $black;
    margin-top: 15px;

    &:first-child {
      margin-top: 0; } }

  &__no-platforms-label {
    padding-left: 40px; } }

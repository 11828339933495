.form {
  &__title {
    color: $purple;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding-left: 40px;
    text-transform: uppercase;

    &--black {
      color: $black; } }

  &__input-label {
    position: absolute;
    right: -85px;
    top: 0;
    transform: translateX(100%);

    &--align-bottom {
      bottom: 0;
      top: auto; } }

  &__input-title {
    letter-spacing: 1px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--error {
      color: $red; } }

  &__input-title-icon {
    flex-shrink: 0;
    line-height: 0;
    height: 20px; }

  &__input {
    position: relative; }

  &__form-block {
    margin-top: 30px;
    padding: 30px 40px;
    background-color: $pale-blue;
    border-radius: 30px;

    &--transparent {
      background: transparent; } }

  &__input-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;

    & > .form__input-block, & > .form__input {
      width: 350px; } }

  &__separator {
    height: 1px;
    background-color: $light-gray; }

  &__document {
    display: flex;
    align-items: center;
    margin-top: 15px; }

  &__document-img {
    flex-shrink: 0;
    margin-right: 11px;
    line-height: 0; }

  &__document-link {
    font-size: 14px;
    line-height: 22px;
    color: $purple; }

  &__document-remove {
    margin-left: 5px;
    line-height: 0; }

  &__images-row {
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-wrap: wrap;

    & > div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0; } } } }

.scene-form {
  .container {
    position: relative; }

  &__form {
    width: 800px; }

  &__save {
    position: relative; }

  &__saved-label {
    width: 300px;
    text-align: center;
    position: absolute;
    top: 60px;
    left: 0;
    opacity: 0;
    transition: opacity .2s ease-out;

    &--visible {
      opacity: 1; } } }

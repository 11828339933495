@import "../../../../styles/colors";

.upload-file {
  width: 150px;

  &__dropzone {
    height: 110px;
    border-radius: 10px;
    box-shadow: 0px 1px 6px 1px rgba(186, 186, 186, 0.2);
    position: relative;
    background-color: $white;
    background-size: cover; }

  &__control {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden; }

  &__loader {
    transform: rotate(0);
    animation: loader 1.3s infinite; }

  &__title {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 18px; }

  &__file {
    text-align: center; }

  &__file-name {
    font-size: 12px;
    font-weight: 300;
    color: $black;
    word-break: break-word;
    margin-top: 5px; }

  &__error {
    color: $red;
    font-size: 10px;
    margin-top: 10px;
    text-align: center; }

  &__delete {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px; } }


@keyframes loader {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }


@import "../../../../styles/colors";

.input-component {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 54px;
  box-sizing: border-box;
  border: 1px solid $light-gray;
  border-radius: 10px;
  box-shadow: 0px 1px 6px 1px rgba(186, 186, 186, 0.2);
  background-color: $white;

  &--auto-width {
    width: 100%; }

  &--with-icon {
    padding-left: 20px;

    .input-component__input {
      padding-left: 10px; } }

  &--label-centered {
    .input-component__input {
      &::placeholder {
        text-align: center; } } }

  &__input {
    flex-grow: 1;
    height: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    letter-spacing: 1px;
    padding: 0 30px;
    background: none;
    border-radius: 10px;

    &::placeholder {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.3); } }

  &--error {
    border: 1px solid $red;

    .input-component__input {
      color: $red;

      &::placeholder {
        color: $red; } } }

  &__error {
    color: $red;
    font-size: 12px;
    line-height: 10px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: -10px;
    width: 100%;
    padding-left: 30px; }

  &__icon {
    margin-right: 10px;
    flex-shrink: 0;
    max-width: 30px;
    max-height: 30px;
    line-height: 0;

    svg {
      max-width: 30px;
      max-height: 30px; } }

  &--disabled {
    background-color: $very-light-blue;
    border: none; } }

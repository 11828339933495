@import '../../../../styles/colors';

.error-label {
  &__error {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background-color: rgba(205, 85, 85, .2);
    border-radius: 10px;
    font-size: 14px;
    line-height: 22px;
    color: $red;
    padding: 12px 18px; }

  &__error-img {
    margin-right: 20px;
    flex-shrink: 0;
    box-sizing: border-box;
    line-height: 0; } }

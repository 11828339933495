@import "../../styles/colors.sass";

.auth-form-layout {
  background-color: $white;
  padding: 55px 50px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;

  &__title {
    color: $black;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: center;
    font-weight: 500; }

  &__form {
    width: 100%;
    form {
      width: 100%; }
    button {
      width: 300px;
      height: 50px;
      border-radius: 25px;
      border: none;
      outline: none;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      cursor: pointer;
      background-color: transparent; } }

  &__error {
    color: $red;
    font-size: 10px;
    line-height: 10px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: -10px;

    &--static {
      margin-bottom: -20px; } }

  &__pass-recovery {
    margin-top: 15px;
    color: $purple;
    text-align: center;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 300; } }

@import '../../../../../styles/colors';

.scenes-list {
  padding-top: 50px;

  &__add-block {
    display: flex;
    justify-content: flex-start;
    align-items: center; }

  &__add-block-text {
    margin-left: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px; }

  &__scenes {
    margin-top: 40px; }

  &__scenes-item {
    width: 500px;
    border-radius: 35px;
    padding: 13px 30px;
    background-color: $white;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    cursor: pointer; }

  &__scenes-item-image {
    margin-right: 30px;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $pale-blue; }

  &__scenes-item-name {
    font-size: 14px;
    line-height: 22px; }

  &__scenes-item-remove {
    display: flex;
    align-items: center;
    margin: 20px 0 0 20px;
    cursor: pointer; } }

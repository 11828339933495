@import "../../../../styles/colors";

.artist-notifications {
  &__body {
    width: 800px; }

  &__notifications {
    margin-top: 20px; }

  &__notifications-item {
    border-radius: 10px;
    padding: 15px 30px;
    padding-top: 30px;
    margin-top: 20px;
    background-color: $pale-blue;

    &--alert {
      background-color: $red;
      color: $white; }

    &--success {
      background-color: $soft-green;
      color: $white; } }

  &__notifications-data {
    display: flex;
    align-items: center; }

  &__notifications-date {
    font-size: 14px;
    text-align: right;
    margin-top: 2px; }

  &__notifications-image {
    flex-shrink: 0;
    margin-right: 15px; }

  &__notifications-text {
    font-size: 14px;
    line-height: 22px; } }

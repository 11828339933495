@import "../../styles/colors.sass";

.main-page {
  padding: 80px 50px 170px 50px;
  background-image: url(../../assets/images/main-page/main-page-bg.jpg);
  background-size: 100% auto;
  background-color: black;
  box-sizing: border-box;
  min-height: 100%;

  &__subtitle {
    margin-top: 50px;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
    color: $white;
    text-align: center; }

  @media (min-width: 1440px) {
    background-image: url(../../assets/images/main-page/main-page-bg@2x.jpg); } }

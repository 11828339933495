@import "../../../../../styles/colors";

.perfomance-artists {
  &__title {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
    display: flex;
    font-weight: 500; }

  &__back {
    line-height: 0;
    margin-right: 25px;
    flex-shrink: 0;
    cursor: pointer; }

  &__controls {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3; }

  &__filter {
    margin-left: 20px;
    position: relative;

    &:hover {
      .perfomance-artists__filter-hint {
        display: block; } } }

  &__list {
    position: relative; }

  &__loader {
    margin-top: 150px;
    margin-bottom: 150px;
    height: 100px; }

  &__filter-form {
    left: 75px;
    top: 50%;
    transform: translate(-124%, -40%);
    position: absolute;

    &--right-sided {
      transform: translate(0, -40%); } }

  &__filter-hint {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 150px;
    transform: translate(-100%, 100%);
    font-size: 12px;
    background-color: $white;
    padding: 5px 10px;
    border-radius: 7px;
    box-shadow: 0px 1px 20px rgba(0,0,0,0.2);
    line-height: 1.3; } }

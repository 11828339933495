@import "./styles/fonts.scss";
@import "./styles/textStyles.sass";
@import "./styles/layout.sass";
@import "./styles/admin-styles.sass";
@import "./styles/form-styles.sass";

html,body {
  min-width: 1280px;
  height: 100%;
  width: 100%;
  font-family: 'stolzl'; }

#root,.app-root {
  height: 100%;
  width: 100%; }

@import '../../../../../styles/colors';

.artist-intersections {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:not(:first-child) {
    margin-top: 20px; }


  &__header {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    padding-left: 20px;
    height: 75px;
    border-radius: 40px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 100%;
    background-color: $white; }

  &__avatar {
    margin-right: 30px;
    flex-shrink: 0;
    background-color: $light-gray;
    border-radius: 50%;
    width: 50px;
    height: 50px; }

  &__body {
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    position: relative;
    z-index: 1;
    width: calc(100% - 75px);
    top: -75px;
    padding-top: 75px;
    margin-bottom: -75px; }

  &__name {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase; }

  &__item {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(210, 218, 228, .25); } }

  &__artist {
    width: 40%;
    border-right: 1px solid rgba(210, 218, 228, .25);
    padding-left: 37px;
    line-height: 1.57;
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px; }

  &__performances {
    width: 60%;
    padding-left: 35px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px; }

  &__performances-item {
    display: flex; }

  &__performance-time {
    font-size: 14px;
    color: $purple;
    margin-right: 20px;
    flex-shrink: 0;
    line-height: 1.57; }

  &__performance-place {
    font-size: 14px;
    line-height: 1.57; } }



.evaluation {
  display: flex;
  flex-direction: column;

  width: 295px;
  height: 180px;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid transparent;
  padding: 40px 59px 40px 69px;
  box-sizing: border-box;

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    margin: 0 0 5px; }

  &__value {
    text-align: center;
    letter-spacing: 3px;
    font-size: 40px;
    text-transform: uppercase;
    margin: 0; }

  &__value--total {
    color: #933EC5; }

  &__separator {
    color: #DDDDDD;
    font-size: 30px; }

  &__max {
    margin: 0;
    font-size: 30px;
    color: #DDDDDD; }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    text-align: center;
    margin: 0;

    color: #8B8B8B; } }

.evaluation--total {
  border: 1px solid #933EC5; }

@import "../../styles/colors";

.auth-form-input {
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 1px 6px 1px rgba(186, 186, 186, 0.2);
  padding-left: 18px;
  display: flex;
  align-items: center;
  border: 1px solid $light-gray;
  box-sizing: border-box;

  &__icon {
    flex-shrink: 0;
    margin-right: 8px;
    margin-top: 2px; }

  &__input {
    flex-grow: 1;
    outline: none;
    border: none;
    height: 100%;
    font-size: 14px;
    font-weight: 300;
    color: $black;
    background-color: transparent;
    padding-left: 7px;
    padding-right: 26px;
    box-sizing: border-box;
    border-radius: 0 25px 25px 0;

    &::placeholder {
      color: $black; } }

  &--error {
    .auth-form-input__input {
      color: $red;

      &::placeholder {
        color: $red; } } } }

@import "../../../../../styles/colors";

.files-group {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 30px 32px;

  &__title {
    font-size: 20px;
    line-height: 1.5; }

  &__download {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
    flex-shrink: 0;
    text-decoration: none; }

  &__download-text {
    color: $purple;
    font-size: 14px;
    line-height: 1.57; }

  &__download-icon {
    transform: rotate(-90deg);
    background-color: $purple;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    border-radius: 50%;
    margin-left: 8px; }

  &__file-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px; }

  &__file-list-item {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;
    width: 31%; }

  &__doc-icon {
    flex-shrink: 0;
    margin-right: 11px;
    line-height: 0; }

  &__file-name {
    font-size: 14px;
    line-height: 1.57;
    text-decoration: none;
    color: $black;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      color: $purple;
      text-decoration: underline; } } }

$black: #000000;
$white: #ffffff;
$purple: #933ec5;
$pale-blue: #f5f8f9;
$very-light-blue: #d2dae4;
$light-gray: #ebebeb;
$red: #cd5555;
$maize: #f4de4d;
$soft-green: #6fbd6c;
$blue: #4d93f4;

@import '../../../styles/colors.sass';

.pass-recovery-success {
  &__text {
    font-size: 14px;
    line-height: 22px;
    color: $black;
    text-align: center;
    margin-top: 15px;
    font-weight: 300; } }

@import "../../../../../styles/colors";

.add-member-button {
  width: 315px;
  height: 75px;
  box-sizing: border-box;
  background-color: $white;
  cursor: pointer;
  border-radius: 35px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $light-gray;
  box-shadow: 0px 1px 6px 1px rgba(186, 186, 186, 0.2);

  &__text {
    letter-spacing: 1px; } }

@import "../../../../styles/colors";
@import "../../../../styles/admin-variables";

.admin-header {
  position: fixed;
  padding: 45px 96px 40px 80px;
  background-color: $white;
  z-index: 3;
  box-sizing: border-box;
  width: calc(100% - #{$admin-sidebar-width});

  &--static {
    position: static; }

  &__title {
    color: $black;
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin: 0;
    display: flex; }

  &__title-back {
    flex-shrink: 0;
    margin-right: 30px;
    line-height: 0;
    cursor: pointer; } }

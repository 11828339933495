@import "../../styles/colors.sass";

.login-form {
  &__forgot-pass {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    color: $purple;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer; } }

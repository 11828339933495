@import '../../styles/colors.sass';

.pass-recovery-form {
  &__text {
    line-height: 22px;
    font-size: 14px;
    color: $black;
    text-align: center;
    margin-top: 10px;
    font-weight: 300; } }

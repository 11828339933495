@import "../../../../styles/colors";

.nav-sidebar {
  width: 250px;
  height: 100%;
  background-color: $black;
  color: $white;
  padding: 30px 0 0;
  position: fixed;
  z-index: 10;

  a {
    text-decoration: none; }

  &__logo {
    display: flex;
    justify-content: center;
    margin: 0 10px 50px 0; }

  &__item {
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
    opacity: .5;
    cursor: pointer;
    &::before {
      display: inline-block;
      content: "";
      height: 100%;
      border-left: 2px solid $black;
      padding-right: 20px; }
    &--decorative {
      margin-left: auto;
      position: relative;
      left: 21px; }
    &:hover {
      opacity: 1; }
    &--active {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) -98%, rgba(74, 144, 226, 0));
      opacity: 1;
      &::before {
        display: inline-block;
        content: "";
        height: 100%;
        border-left: 2px solid $white;
        opacity: .4;
        padding-right: 20px; } }

    p {
      font-size: 14px;
      color: $white;
      padding-left: 20px; } }

  &__item-icon {
    text-align: center;
    line-height: 0;
    width: 20px; } }

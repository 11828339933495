.scene-schedule {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__performance-list {
    width: 560px; }

  &__timeline {
    width: 585px; } }

@import '../../../../styles/colors';

.circle-button {
  width: 50px;
  height: 50px;
  background-color: $purple;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer; }

@import "../../../../../styles/colors";

.input-label {
  background-color: $pale-blue;
  padding: 15px 25px;
  width: 460px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  color: $black;
  line-height: 22px;

  span {
    color: $purple; }

  &--layered {
    opacity: 0; }

  &--focused {
    background-color: $purple;
    color: $white;
    opacity: 1;

    span {
      color: $maize; } }

  a {
    color: $purple; } }

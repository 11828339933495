@import "../../../../../styles/colors";

.performance-artists-filter {
  width: 430px;
  border-radius: 10px;
  box-shadow: 0 1px 20px rgba(0,0,0,.1);
  box-sizing: border-box;
  background-color: $white;

  &__close {
    width: 17px;
    height: 17px;
    background-image: url(../../../../../assets/icons/close.svg);
    line-height: 0;
    background-size: contain;
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1; } }

  &__body {
    padding: 30px 45px 0px 35px; }

  &__genre-select {
    .genre-radio-button {
      width: 87px; } }

  &__timeline-input {
    width: 110px;
    margin: 0 10px; }

  &__button {
    height: 50px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
    color: $white;
    cursor: pointer;

    &--reset {
      background-color: $very-light-blue;
      border-radius: 0px 0px 0px 10px; }

    &--apply {
      background-color: $purple;
      border-radius: 0px 0px 10px 0px; } } }

@import "../../../../styles/colors";

.tab-menu {
  color: white;
  display: flex;

  &--dark {
    .tab-menu__tab {
      color: $black; } }

  &__tab {
    font-size: 20px;
    line-height: 1.5;
    color: $white;
    opacity: 0.3;
    margin-right: 60px;
    position: relative;
    cursor: pointer;

    &--active {
      opacity: 1;

      &::after {
        display: block;
        content: '';
        width: 54px;
        height: 4px;
        border-radius: 2px;
        background-color: $purple;
        margin-top: 12px; } } } }

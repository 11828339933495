@import "../../../../../styles/colors";

.expert-platform {
  display: flex;
  align-items: center;
  height: 100px;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 13px 17px;
  box-sizing: border-box;

  &:not(:first-child) {
    margin-top: 30px; }

  &__image {
    flex-shrink: 0;
    width: 75px;
    height: 75px;
    background-color: $pale-blue;
    background-size: cover;
    margin-right: 40px;
    border-radius: 50%; }

  &__title {
    color: $black; } }

@import "../../styles/colors.sass";

.auth-info {
  background-color: $purple;
  padding: 55px 50px;
  box-sizing: border-box;
  color: $white;

  &__title {
    line-height: 1.5;
    letter-spacing: 2px;
    font-size: 20px; }

  &__text {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 30px;
    text-align: center; } }

@import "../../../../styles/colors";

.artist-form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .form__input-label {
    display: none; }

  &--active-hints {
    .form__input-label {
      display: block; } }

  &__form {
    width: 800px;

    button {
      width: 300px;
      height: 50px;
      border-radius: 25px;
      border: none;
      outline: none;
      display: block;
      padding: 0;
      cursor: pointer;
      background-color: transparent; } }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    svg {
      transform: rotate(0);
      animation: loader 1.3s infinite; } }

  &__images-row {
    justify-content: space-between;

    & > div {
      &:nth-child(2) {
        margin-right: auto; }
      &:nth-child(3) {
        margin-left: auto; } } }

  &__submit-area {
    padding: 30px 40px; }

  &__sidebar {
    width: 420px; } }

@keyframes loader {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@import "../../../../../../styles/colors";

.schedule-timeline {
  width: 585px;
  position: relative;
  min-height: 100px;

  &__date {
    padding-left: 60px;
    margin-top: 40px;
    font-size: 18px;
    color: $purple;

    &:first-child {
      margin-top: 0; } }

  &__list {
    margin-top: 20px;
    position: relative;
    display: flex; }

  &__list-items {
    margin-left: 60px; }

  &__list-item {
    margin-top: 45px;
    position: relative;
    display: flex;

    &--active {
      .schedule-timeline__list-item-circle {
        background-color: $purple; }

      .schedule-timeline__list-item-time {
        color: $purple; }

      .schedule-timeline__list-item-label {
        color: $purple;
        opacity: 1;

        &:hover {
          opacity: 1; } } }

    &:first-child {
      margin-top: 0; } }

  &__list-item-circle {
    content: '';
    left: -76px;
    top: 0;
    width: 30px;
    height: 30px;
    background-color: $very-light-blue;
    border-radius: 50%;
    position: absolute; }

  &__list-line {
    background-color: $very-light-blue;
    width: 1px;
    //height: calc(100% - #{$list-item-height})
    //position: absolute
    //left: -60px
    // top: 50%
 }    // transform: translate(0, -50%)

  &__list-item-time {
    letter-spacing: 1px;
    width: 130px;
    margin-right: 10px;
    line-height: 30px;
    flex-shrink: 0; }

  &__list-item-label {
    opacity: .2;
    letter-spacing: 1px;
    line-height: 30px;
    cursor: pointer;

    &:hover {
      opacity: .4; } } }



.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  svg {
    transform: rotate(0);
    animation: loader 1.3s infinite; } }

@keyframes loader {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

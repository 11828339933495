@import "../../../../../styles/colors";

$list-item-height: 100px;

.perfomance-list {
  &__loader {
    height: 200px;
    position: relative; }

  &__list {
    position: relative; }

  &__list-item {
    margin-top: 20px; } }

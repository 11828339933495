@import "../../../../styles/colors";

.admin-layout {
  display: flex;
  height: 100vh;

  &__body {
    width: 1280px;
    margin-left: 250px;
    padding-bottom: 46px; } }

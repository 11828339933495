@import "../../../../styles/colors";

.request-error-modal {
  padding: 15px 0;

  &__icon {
    margin: auto;
    line-height: 0;
    text-align: center; }

  &__errors {
    text-align: center;
    margin-top: 30px; }

  &__error-text {
    margin-top: 15px;
    color: $red;
    letter-spacing: 1px;
    line-height: 30px; } }

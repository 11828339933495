@import './colors.sass';
@import './admin-variables.sass';

.react-tabs {
  &__tab-list {
    &--white {
      .react-tabs__tab {
        color: white; } } }

  &__tab {
    border: none;
    position: initial;
    padding: 0;
    font-family: Stolzl;
    font-size: 20px;
    line-height: 1.5;
    color: $black;
    opacity: 0.3;
    margin-right: 60px;

    &:focus {
      box-shadow: none;
      border: none;

      &:after {
        position: initial;
        height: 0px; } }

    &-list {
      border: none;
      margin: 0; }

    &::after {
      display: none; }

    &--selected {
      border: none;
      border-radius: 0;
      opacity: 1;
      background: transparent;

      &::after {
        display: block;
        content: '';
        width: 54px;
        height: 4px;
        background-color: $purple;
        border-radius: 2px;
        margin-top: 12px; } } } }


.admin-layout-content {
  margin-top: 115px;

  &__header {
    padding: 0 96px 20px 80px;
    position: fixed;
    background-color: $white;
    z-index: 2;
    box-sizing: border-box;
    width: calc(100% - #{$admin-sidebar-width});
    min-width: calc(1340px - #{$admin-sidebar-width});
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1); }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 1104px; }

  &__body {
    padding: 178px 96px 30px 80px;
    position: relative;
    z-index: 1;

    &--padding-top-s {
      padding-top: 110px; }

    &--padding-top-0 {
      padding-top: 0; } }


  &__counter {
    display: flex;
    content: '';
    width: 42px;
    height: 14px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: $purple;
    font-size: 8px;
    color: $white;
    align-items: center;
    justify-content: center; }

  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: calc(50% + #{$admin-sidebar-width/2});
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; }

  &__add {
    display: flex;
    align-items: center; }

  &__add-title {
    color: $black;
    font-size: 20px;
    font-weight: 500;
    margin: 0 25px 0 0; }

  &__empty {
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 10px; } }


.form-time-picker {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 54px;
  box-sizing: border-box;
  border: 1px solid $light-gray;
  border-radius: 10px;
  box-shadow: 0px 1px 6px 1px rgba(186, 186, 186, 0.2);
  background-color: $white;

  &--xxs {
    input.rc-time-picker-input {
      padding: 0 20px;
      text-align: center; } }

  &--error {
    border: 1px solid $red;

    input.rc-time-picker-input {
      &::placeholder {
        color: $red; } } }

  &__error {
    color: $red;
    font-size: 12px;
    line-height: 10px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: -20px;
    width: 100%;
    padding-left: 30px; }

  .rc-time-picker-input {
    border: none;
    height: 100%;
    flex-grow: 1;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 1px;
    padding: 0 30px;
    background: none;
    border-radius: 10px;

    &::placeholder {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.3); } } }

.form-time-picker-popup {
  .rc-time-picker-panel-input-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 54px;
    box-sizing: border-box;
    border: 1px solid $light-gray;
    box-shadow: 0px 1px 6px 1px rgba(186, 186, 186, 0.2);
    background-color: $white; }

  .rc-time-picker-panel-input {
    border: none;
    height: 100%;
    flex-grow: 1;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 1px;
    padding: 0 10px;
    background: none;
    text-align: center;

    &::placeholder {
      color: transparent; } }

  .rc-time-picker-panel-inner {
    border-radius: 10px 0px 0px 10px;
    margin-top: 3px;
    overflow: hidden;
    border: none; } }

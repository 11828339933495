.scene-info-layout {
  padding-top: 50px;

  &__title {
    display: flex;
    align-items: center; }

  &__back {
    flex-shrink: 0;
    margin-right: 30px;
    line-height: 0;
    cursor: pointer; } }

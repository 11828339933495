@font-face {
  font-family: 'Stolzl';
  src: url('../assets/fonts/stolzl-light.woff2') format('woff2'),
      url('../assets/fonts/stolzl-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../assets/fonts/stolzl-medium.woff2') format('woff2'),
      url('../assets/fonts/stolzl-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../assets/fonts/stolzl-bold.woff2') format('woff2'),
      url('../assets/fonts/stolzl-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../assets/fonts/stolzl-regular.woff2') format('woff2'),
      url('../assets/fonts/stolzl-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


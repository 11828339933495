@import "../../../styles/colors";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.32);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;

  &__body {
    width: 880px;
    min-height: 300px;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 80px;
    box-sizing: border-box;

    &--auto-height {
      min-height: auto; } }

  &__title {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center; } }

@import "../../../../styles/colors";

.files-categories {
  padding-left: 80px;
  padding-right: 96px;

  &__card {
    width: 100%;
    max-width: 340px;
    height: 200px;
    box-shadow: 0px 1px 20px rgba(0,0,0,.1);
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;

    &:nth-child(n + 4) {
      margin-top: 15px; } }

  &__card-title {
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    color: $black; }

  &__file-cards {
    margin-top: 15px;
    flex-wrap: wrap;

    .files-categories__card {
      cursor: default; } }

  &__download {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 30px;
    background-color: transparent;
    border: none;
    text-decoration: none;

    &--disabled {
      pointer-events: none; }

    .loader {
      width: 30px;
      height: 30px; } }

  &__download-icon {
    margin-right: 15px;
    transform: rotate(-90deg);
    background-color: $purple;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px; }

  &__download-text {
    font-size: 14px;
    line-height: 22px;
    color: $black;
    text-decoration: none;

    &:hover {
      color: $purple;
      text-decoration: underline; } } }

.toolTip {
  max-width: 400px !important;
  text-align: center;
  color: #000000;

  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  opacity: 1 !important;

  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1); }
.exports {
  display: grid;
  grid-gap: calc(1vw);
  grid-template-columns: repeat(3, 1fr); }

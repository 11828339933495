@import "../../../../styles/colors";

.form-select {
  display: flex;
  align-items: center;
  height: 54px;
  box-sizing: border-box;
  border: 1px solid $light-gray;
  border-radius: 10px;
  box-shadow: 0px 1px 6px 1px rgba(186, 186, 186, 0.2);
  background-color: $white;
  position: relative;

  &--small {
    .form-select__select {
      padding: 0 0 0 20px; } }

  &--select-default-option {
    .form-select__select {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.3); } }

  &--error {
    border: 1px solid $red;

    select.form-select__select {
      color: $red; } }

  &::after {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #933ec5 transparent transparent transparent;
    pointer-events: none; }

  &__select {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    padding: 0;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 0 0 0 30px;
    outline: none;
    appearance: none;

    option {
      font-weight: 300;
      text-align: center;
      text-indent: 10px;
      padding-left: 10px;
      height: 50px;
      outline: none;
      color: $black; }

    &::-ms-expand {
      display: none; } } }

@import  "../../../styles/colors";

.footer {
  color: $white;
  margin-top: -80px;
  padding-top: 100px;
  padding-bottom: 60px;
  background-image: url(../../../assets/images/main-page/main-page-footer--black.png), linear-gradient(to top, $black 0%, $black 70%,$black 70%, transparent 70%, transparent 100%);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;

  @media (min-width: 1440px) {
    background-image: url(../../../assets/images/main-page/main-page-footer--black@2x.png), linear-gradient(to top, $black 0%, $black 70%,$black 70%, transparent 70%, transparent 100%); }


  &__left,&__middle,&__right {
    width: 33.3%; }

  &__middle {
    text-align: center; }

  &__right {
    text-align: right; }

  &__press-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 2px; }

  &__text {
    letter-spacing: 2px;
    font-weight: 300;
    line-height: 1.88;
    color: $white;
    text-decoration: none;
    display: inline-block;
    border-bottom: 2px solid transparent;
    transition: border-color .2s ease-out;

    &--underlined {
      &:hover {
        border-color: $purple; } } }

  &__press-text {
    margin-top: 16px;
    font-size: 9px;
    line-height: 20px;
    letter-spacing: 1px; }

  &__logo {
    background-image: url(../../../assets/images/main-page/umn--white.png);
    width: 97px;
    height: 73px;
    background-size: contain;
    margin: auto;
    transition: transform .2s ease-out;

    &:hover {
      transform: scale(1.1); } }

  &__doubletapp {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    display: inline-flex;
    margin-top: 20px;

    svg {
      margin-left: 8px; } }

  &__stores {
    text-align: right;
    margin-top: 16px;

    a {
      transition: transform .2s ease-out;
      display: inline-block;

      &:hover {
        transform: scale(1.1); } } }

  &__social {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px; }

  &__social-item {
    background-color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    transition: transform .2s ease-out;
    box-sizing: border-box;

    &--telegram {
      padding-right: 3px; }

    &:not(:last-child) {
      margin-right: 24px; }

    &:hover {
      transform: scale(1.1); } }

  &--white {
    background-color: $white;
    color: $white;
    margin-top: 0;
    padding-top: 70px;

    .footer__text {
      color: $black; }

    .footer__logo {
      background-image: url(../../../assets/images/main-page/umn.png); }

    .footer__social-item {
      background-color: $black; } } }

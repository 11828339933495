.platform-form {
  padding-top: 40px;

  .form__input-label {
    display: none; }

  &--active-hints {
    .form__input-label {
      display: block; } }

  &__form {
    width: 800px; }

  &__photo-input-label.form__input-label {
    top: 50%;
    transform: translate(100%, -50%); }

  &__map {
    border-radius: 10px;
    overflow: hidden;
    height: 200px; }

  &__address-input {
    position: relative; }

  &__suggestions {
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    border-radius: 10px;
    z-index: 2;
    width: 100%; }

  &__suggestions-loading {
    text-align: center;
    padding: 10px 0; }

  &__suggestions-item {
    padding: 7px 20px;

    &:first-child {
      border-radius: 10px 10px 0px 0px; }

    &:last-child {
      border-radius: 0px 0px 10px 10px; } } }

@import "../../../../styles/colors";

.button-with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  background-color: $white;
  border-radius: 25px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 5px 15px;

  &__icon {
    flex-shrink: 0;
    margin-right: 15px;
    margin-top: 2px; }

  &__text {
    color: $black;
    letter-spacing: 2px; }

  &:active {
    opacity: 0.8; } }

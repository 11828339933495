@import "../../../../styles/colors";

.crm-list-item {
  display: flex;
  align-items: center;
  height: 70px;
  object-fit: contain;
  border-radius: 35px;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  padding: 0 30px;
  margin-bottom: 15px;

  &__avatar {
    width: 45px;
    height: 45px;
    object-fit: contain;
    border-radius: 50%;
    background-color: $very-light-blue;
    margin-right: 25px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

  &__name {
    flex: 1; }

  &__status-indicator {
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background-color: $white;
    margin-left: 25px; }

  &--grey {
    background-color: $light-gray; }
  &--yellow {
    background-color: $maize; }
  &--green {
    background-color: $soft-green; }
  &--red {
    background-color: $red; }
  &--blue {
    background-color: $blue; }

  &__rating {
    display: flex;
    align-items: center;
    flex-shrink: 0; }

  &__rating-amount {
    font-size: 14px;
    line-height: 22px;
    margin-right: 15px; } }

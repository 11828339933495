@import "../../../../styles/colors";

.genre-radio-button {
  width: 80px;
  display: block;
  user-select: none;
  cursor: pointer;

  & > input {
    width: 0;
    height: 0;
    border: none;
    background: transparent;
    outline: none;
    line-height: 0;
    font-size: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    opacity: 0;

    &:checked {
      & + .genre-radio-button__icon {
        background-color: $purple; } } }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $very-light-blue;
    margin: auto;

    svg {
      max-width: 25px;
      max-height: 25px;
      fill: #fff; } }

  &__caption {
    font-size: 11px;
    margin-top: 12px;
    line-height: 22px;
    color: $black;
    font-weight: 300;
    text-align: center; }

  &--disabled {
    cursor: default; } }

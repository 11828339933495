.add-element {
  display: flex;
  align-items: center;

  &__add-block-text {
    margin-left: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px; } }

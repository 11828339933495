@import "../../../../styles/colors";

.artist-sidebar {
  width: 420px;

  &__comments {
    max-height: 400px;
    overflow-y: scroll; }

  &__comment-input {
    margin-top: 30px; }

  &__comment-input-title {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 500; }

  &__comments-section {
    margin-top: 50px;

    &:first-child {
      margin-top: 20px; } }

  &__comments-title {
    line-height: 30px;
    letter-spacing: 1px; }

  &__comments-item {
    padding: 22px;
    background-color: $pale-blue;
    margin-top: 20px;
    border-radius: 10px; }

  &__comments-item-date {
    font-size: 12px;
    margin-top: 5px; }

  &__comments-item-text {
    margin-top: 13px;
    font-weight: 300; }

  &__no-comments {
    font-size: 12px;
    margin-top: 10px; }

  &__tabs {
    display: flex;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 37px;
    padding-left: 0;
    height: 50px;
    margin: 0; }

  &__tabs-item {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    cursor: pointer;

    &--selected {
      border-radius: 37px;
      background-color: $purple;
      color: $white; } } }

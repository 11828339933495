@import "../../../../styles/colors";

.textarea-component {
  height: 180px;
  border: 1px solid $light-gray;
  border-radius: 10px;
  box-shadow: 0px 1px 6px 1px rgba(186, 186, 186, 0.2);
  background-color: $white;

  &__textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    letter-spacing: 1px;
    padding: 15px 30px 0 30px;
    box-sizing: border-box;
    background: none;
    border-radius: 10px;

    &::placeholder {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.3); } }

  &--error {
    border: 1px solid $red;

    .input-component__input {
      color: $red;

      &::placeholder {
        color: $red; } } }

  &__error {
    color: $red;
    font-size: 12px;
    line-height: 10px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: -10px;
    width: 100%;
    padding-left: 30px; }

  &--disabled {
    background-color: $very-light-blue;
    border: none; } }

@import './colors';

.page-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: $white;

  &--align-left {
    text-align: left; }

  &--align-right {
    text-align: right; }

  &--align-center {
    text-align: center; }

  &--black {
    color: $black; } }

.page-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 2px;
  color: $black;

  &--spacing-s {
    letter-spacing: 1px; }

  &--align-left {
    text-align: left; }

  &--align-right {
    text-align: right; }

  &--align-center {
    text-align: center; } }

.page-text {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  color: $black;

  &--green {
    color: $soft-green; }

  &--align-left {
    text-align: left; }

  &--align-right {
    text-align: right; }

  &--align-center {
    text-align: center; } }

.delete-member-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 52px;

  &__text {
    letter-spacing: 1px;
    line-height: 30px;
    margin-right: 13px; }

  &__icon {
    flex-shrink: 0; } }

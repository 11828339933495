@import '../../../../styles/colors';

.label {
  width: 220px;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0px 1px 20px rgba(0,0,0,.1);
  padding: 15px;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0); }


  &--bottom {
    &::after {
      top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 10px 7px;
      border-color: transparent transparent #ffffff transparent; } }

  &--top {
    &::after {
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 7px 0 7px;
      border-color: #ffffff transparent transparent transparent; } }

  &__item {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;

    &:not(:first-child) {
      margin-top: 10px; } }

  &__icon {
    margin-right: 15px;
    flex-shrink: 0;
    line-height: 0; } }

@import "../../../../../styles/colors";

.comment-input {
  line-height: 0;

  &__input {
    border-radius: 10px;
    background-color: $pale-blue;
    padding: 24px;
    font-size: 14px;
    line-height: 22px;
    max-width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 200px;
    border: none;
    outline: none;
    box-sizing: border-box;
    font-weight: 300; } }
